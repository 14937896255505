/* eslint-disable no-unused-expressions */
import React from 'react';
import { InfoIcon } from 'lucide-react';
import { useMutation } from '@apollo/client';
import nProgress from 'nprogress';
import { useRouter } from 'next/router';
import { ACTIONS, ShipmentActions } from './shippingReducer';
import { Input } from '../../../../common/input/Input';
import { Select } from '../../../../common/select/Select';
import { Order, Shipment, ShippingType } from '../../../../../models';
import { shippingTypes } from '../../../../../utils/calculatorInfo';
import { Button } from '../../../../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../ui/tooltip';
import { CREATE_ORDER, UPDATE_ORDER } from '../../../../../graphql/mutation';
import { useToast } from '../../../../../hooks/useToast';
import { useUser } from '../../../../../hooks';

interface ShipomentInformationProps {
  state: Partial<Shipment>;
  dispatch: React.Dispatch<ShipmentActions>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  isUpdate?: boolean;
  order?: Order;
}

function ShipmentInformation({
  state,
  dispatch,
  setSelected,
  isUpdate = false,
  order,
}: ShipomentInformationProps) {
  const [createOrder] = useMutation(CREATE_ORDER);
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const { toast } = useToast();
  const router = useRouter();
  const [user] = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    nProgress.start();

    // Inicializa una lista para almacenar mensajes de error
    const errors = [];

    if (
      state.shippingType === 'air' &&
      (!Number(state.weight) || state.weight === '')
    ) {
      errors.push('El peso debe ser un número válido y no puede estar vacío.');
    } else if (
      state.shippingType === 'sea' &&
      (!Number(state.cbm) || state.cbm === '')
    ) {
      errors.push('El CBM debe ser un número válido y no puede estar vacío.');
    }

    if (!state.address || state.address.trim() === '') {
      errors.push('La dirección no puede estar vacía.');
    }

    if (!state.merchType || state.merchType.trim() === '') {
      errors.push('El tipo de mercancía no puede estar vacío.');
    }

    if (!Number(state.boxesNumber) || state.boxesNumber === '') {
      errors.push(
        'El número de cajas debe ser un número válido y no puede estar vacío.'
      );
    }

    if (state.trackingNumber && state.trackingNumber.includes(' ')) {
      errors.push(
        'El número de tracking no puede contener espacios en blanco.'
      );
    }

    if (errors.length > 0) {
      toast({
        variant: 'destructive',
        title: 'Error en la validación',
        description: errors.join('\n'),
      });
      nProgress.done();
      return;
    }

    const orderData = {
      user: user._id,
      fistLine: state.address,
      dangerous: state.dangerous,
      replica: state.replica,
      postalCode: state.postalCode,
      merchType: state.merchType,
      boxesNumber: state.boxesNumber,
      shipmentWeight: state.weight,
      cbm: state.cbm,
      trackingNumber: state.trackingNumber || 'Sin número de tracking',
      status: 'pending',
      modality: 1,
      approved: 'WAITING_FOR_APPROVAL',
      shippingType: state.shippingType,
      title: isUpdate ? 'Envío actualizado por el cliente' : 'Envío creado',
      movementType: 'na',
    };

    try {
      if (isUpdate) {
        await updateOrder({
          variables: {
            data: {
              ...orderData,
              orderId: order._id,
            },
          },
        });
        toast({
          variant: 'success',
          title: 'Éxito',
          description: 'Tu envío ha sido actualizado exitosamente.',
        });
        // reload the page
        router.reload();
      } else {
        await createOrder({
          variables: {
            data: orderData,
          },
        });
        toast({
          variant: 'success',
          title: 'Éxito',
          description: 'Tu envío ha sido creado exitosamente.',
        });
        router.push('/app/orders');
      }
    } catch (err) {
      console.error('Error al crear/actualizar el envío:', err);
      const errorMessage =
        err.message || 'Ocurrió un error desconocido al guardar tu envío.';
      toast({
        variant: 'destructive',
        title: 'Error al crear/actualizar el envío',
        description: errorMessage,
      });
    } finally {
      nProgress.done();
    }
  };

  return (
    <div className="w-full h-fit px-4 py-2 rounded-md flex flex-col gap-4">
      <div className=" flex w-full flex-col justify-center items-center md:flex-row md:justify-start gap-3">
        <Select
          label="Tipo de envío"
          labelClassName="w-full md:w-1/2"
          className={isUpdate ? 'cursor-not-allowed' : ''}
          onChange={(e) => {
            dispatch({
              type: ACTIONS.SHIPPING_TYPE,
              payload: e.target.value as ShippingType,
            });
          }}
          value={state.shippingType}
          disabled={isUpdate}
        >
          <option key="sea" value="sea">
            Marítimo
          </option>
          {/* <option key="air" value="air">
            Aéreo
          </option> */}
        </Select>
        <Input
          label="Tipo de mercancía (productos)"
          labelClassName="w-full md:w-1/2"
          onChange={(e) => {
            dispatch({ type: ACTIONS.MERCH_TYPE, payload: e.target.value });
          }}
          value={state.merchType}
        />
      </div>
      <div className=" flex w-full flex-col justify-center items-center md:flex-row md:justify-start gap-3">
        <div className="w-full md:w-1/2 items-start gap-2 flex ">
          <Select
            label="¿Su mercancía es peligrosa?"
            labelClassName="w-full"
            onChange={(e) => {
              dispatch({
                type: ACTIONS.DANGEROUS,
                payload: e.target.value === 'yes',
              });
            }}
            value={state.dangerous ? 'yes' : 'no'}
          >
            <option key="yes" value="yes">
              Sí
            </option>
            <option key="no" value="no">
              No
            </option>
          </Select>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <InfoIcon className="w-8 h-8 flex items-center justify-center rounded-full bg-primary-500 text-white">
                  <i className="fas fa-plus" />
                </InfoIcon>
              </TooltipTrigger>
              <TooltipContent>
                <Tooltip>
                  <p>
                    Si tu mercancía contiene: baterías, inflamables, químicos y
                    líquidos de cualquier tipo.
                  </p>
                </Tooltip>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <Select
          label="¿Su mercancía es réplica?"
          labelClassName="w-full md:w-1/2"
          onChange={(e) => {
            dispatch({
              type: ACTIONS.REPLICA,
              payload: e.target.value === 'yes',
            });
          }}
          value={state.replica ? 'yes' : 'no'}
        >
          <option key="yes" value="yes">
            Sí
          </option>
          <option key="no" value="no">
            No
          </option>
        </Select>
      </div>
      <hr className="w-full border-neutral-300 " />
      <div className="flex flex-col md:grid grid-cols-3 grid-rows gap-3 items-center">
        {state.shippingType === 'sea' ? (
          <Input
            label="CBM (volumen total en mt3)"
            labelClassName="w-full"
            onChange={(e) => {
              dispatch({ type: ACTIONS.CBM, payload: e.target.value });
            }}
            value={state.cbm}
            type="number"
          />
        ) : (
          <Input
            label="Peso (peso total en kg)"
            labelClassName="w-full"
            onChange={(e) => {
              dispatch({ type: ACTIONS.WEIGHT, payload: e.target.value });
            }}
            value={state.weight}
          />
        )}
        <Input
          label="N° de cajas"
          labelClassName="w-full"
          onChange={(e) => {
            dispatch({ type: ACTIONS.BOXES_NUMBER, payload: e.target.value });
          }}
          value={state.boxesNumber}
          type="number"
        />
      </div>
      <hr className="w-full border-neutral-300 " />
      <div className="flex flex-col md:flex-row w-full gap-3">
        <Input
          label="¿Alguna nota adicional?"
          labelClassName="w-full md:w-1/3"
          onChange={(e) => {
            dispatch({ type: ACTIONS.ORIGIN_CITY, payload: e.target.value });
          }}
          value={state.originCity}
        />
        <div className="flex flex-row w-full gap-2 items-start ">
          <Input
            label="N° de tracking (Interno en China)"
            labelClassName="w-full md:w-1/3"
            onChange={(e) => {
              dispatch({
                type: ACTIONS.TRACKING_NUMBER,
                payload: e.target.value,
              });
            }}
            value={state.trackingNumber}
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <InfoIcon className="w-8 h-8 flex items-center justify-center rounded-full bg-primary-500 text-white">
                  <i className="fas fa-plus" />
                </InfoIcon>
              </TooltipTrigger>
              <TooltipContent>
                <Tooltip>
                  <p>
                    Este número de tracking es el que te da el proveedor cuando
                    envía tu mercancía a nuestro almacén en China.
                  </p>
                </Tooltip>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full gap-3">
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelected('address');
          }}
          className=""
          variant="outline"
        >
          Anterior
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          className=""
        >
          {isUpdate ? 'Actualizar' : 'Crear'}
        </Button>
      </div>
    </div>
  );
}

export default ShipmentInformation;

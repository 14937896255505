/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';

export interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  labelClassName?: string;
}
function _TextArea({
  label,
  labelClassName,
  className = '',
  name,
  ...rest
}: TextareaProps) {
  return (
    <label
      className={` flex flex-col w-full h-fit text-sm font-medium gap-2 px-4 ${labelClassName}  `}
      htmlFor={name}
    >
      {label}
      <textarea
        className={`text-sm rounded-md h-40 max-h-68 block w-full text-font-neutral-400  border border-neutral-200 placeholder-neutral-400  outline-none focus:outline-none focus:shadow-none bg-font-white  text-font-black focus:ring-0 ${className}`}
        name={name}
        {...rest}
      />
    </label>
  );
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => <_TextArea ref={ref} {...props} />
);

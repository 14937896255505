import { Shipment, ShippingType } from '../../../../../models/index';

export const ACTIONS = {
  FIRST_NAME: 'SET_FIRST_NAME',
  LAST_NAME: 'SET_LAST_NAME',
  DNI: 'SET_DNI',
  EMAIL: 'SET_EMAIL',
  PHONE: 'SET_PHONE',
  ADDRESS: 'SET_ADDRESS',
  ADDRESS_URL: 'SET_ADDRESS_URL',
  POSTAL_CODE: 'SET_POSTAL_CODE',
  SHIPPING_TYPE: 'SET_SHIPPING_TYPE',
  MERCH_TYPE: 'SET_MERCH_TYPE',
  BOXES_NUMBER: 'SET_BOXES_NUMBER',
  // WIDTH: 'SET_WIDTH',
  // LENGTH: 'SET_LENGTH',
  // HEIGHT: 'SET_HEIGHT',
  WEIGHT: 'SET_WEIGHT',
  CBM: 'SET_CBM',
  ORIGIN_CITY: 'SET_ORIGIN_CITY',
  TRACKING_NUMBER: 'SET_TRACKING_NUMBER',
  BOXES_PICTURES: 'SET_BOXES_PICTURES',
  PACKING_LIST: 'SET_PACKING_LIST',
  INVOICE: 'SET_INVOICE',
  DEFAULT: 'SET_DEFAULT',
  SHIPPING_COST: 'SET_SHIPPING_COST',
  DELIVERY_INSTRUCTIONS: 'SET_DELIVERY_INSTRUCTIONS',
  PRODUCT_TYPE: 'SET_PRODUCT_TYPE',
  RECEPTION_STATE: 'SET_RECEPTION_STATE',
  APPROVED: 'SET_APPROVED',
  DANGEROUS: 'SET_DANGEROUS',
  REPLICA: 'SET_REPLICA',
} as const;

export type ShipmentActions =
  | { type: 'SET_FIRST_NAME'; payload: string }
  | { type: 'SET_LAST_NAME'; payload: string }
  | { type: 'SET_DNI'; payload: string }
  | { type: 'SET_EMAIL'; payload: string }
  | { type: 'SET_PHONE'; payload: string }
  | { type: 'SET_ADDRESS'; payload: string }
  | { type: 'SET_ADDRESS_URL'; payload: string }
  | { type: 'SET_POSTAL_CODE'; payload: string }
  | { type: 'SET_SHIPPING_TYPE'; payload: ShippingType }
  | { type: 'SET_SHIPPING_COST'; payload: string }
  | { type: 'SET_PRODUCT_TYPE'; payload: string }
  | { type: 'SET_DELIVERY_INSTRUCTIONS'; payload: string }
  | { type: 'SET_MERCH_TYPE'; payload: string }
  | { type: 'SET_BOXES_NUMBER'; payload: string }
  // | { type: 'SET_WIDTH'; payload: string }
  // | { type: 'SET_LENGTH'; payload: string }
  // | { type: 'SET_HEIGHT'; payload: string }
  | { type: 'SET_WEIGHT'; payload: string }
  | { type: 'SET_CBM'; payload: string }
  | { type: 'SET_ORIGIN_CITY'; payload: string }
  | { type: 'SET_TRACKING_NUMBER'; payload: string }
  | { type: 'SET_BOXES_PICTURES'; payload: [string] }
  | { type: 'SET_PACKING_LIST'; payload: [string] }
  | { type: 'SET_APPROVED'; payload: boolean }
  | { type: 'SET_RECEPTION_STATE'; payload: string }
  | { type: 'SET_DANGEROUS'; payload: boolean }
  | { type: 'SET_REPLICA'; payload: boolean }
  | { type: 'SET_INVOICE'; payload: [string] };

export const initialState: Shipment = {
  firstName: '',
  lastName: '',
  dni: '',
  email: '',
  phone: '',
  address: '',
  addressUrl: '',
  postalCode: '',
  shippingType: 'sea',
  merchType: '',
  boxesNumber: '',
  // width: '',
  // length: '',
  // height: '',
  weight: '',
  shippingCost: '',
  productType: '',
  deliveryInstructions: '',
  originCity: '',
  trackingNumber: '',
  boxesPictures: [''],
  packingList: [''],
  invoice: [''],
  approved: false,
  dangerous: false,
  replica: false,
  receptionState: 'caracas',
  cbm: '',
};

export function shippingReducer(
  state: Shipment,
  action: ShipmentActions
): Shipment {
  switch (action.type) {
    case ACTIONS.FIRST_NAME:
      return { ...state, firstName: action.payload };
    case ACTIONS.LAST_NAME:
      return { ...state, lastName: action.payload };
    case ACTIONS.DNI:
      return { ...state, dni: action.payload };
    case ACTIONS.EMAIL:
      return { ...state, email: action.payload };
    case ACTIONS.PHONE:
      return { ...state, phone: action.payload };
    case ACTIONS.ADDRESS:
      return { ...state, address: action.payload };
    case ACTIONS.ADDRESS_URL:
      return { ...state, addressUrl: action.payload };
    case ACTIONS.POSTAL_CODE:
      return { ...state, postalCode: action.payload };
    case ACTIONS.SHIPPING_TYPE:
      return { ...state, shippingType: action.payload };
    case ACTIONS.MERCH_TYPE:
      return { ...state, merchType: action.payload };
    case ACTIONS.BOXES_NUMBER:
      return { ...state, boxesNumber: action.payload };
    case ACTIONS.DANGEROUS:
      return { ...state, dangerous: action.payload };
    case ACTIONS.REPLICA:
      return { ...state, replica: action.payload };
    // case ACTIONS.WIDTH:
    //   return { ...state, width: action.payload };
    // case ACTIONS.LENGTH:
    //   return { ...state, length: action.payload };
    // case ACTIONS.HEIGHT:
    //   return { ...state, height: action.payload };
    case ACTIONS.WEIGHT:
      return { ...state, weight: action.payload };
    case ACTIONS.CBM:
      return { ...state, cbm: action.payload };
    case ACTIONS.ORIGIN_CITY:
      return { ...state, originCity: action.payload };
    case ACTIONS.TRACKING_NUMBER:
      return { ...state, trackingNumber: action.payload };
    case ACTIONS.BOXES_PICTURES:
      return { ...state, boxesPictures: action.payload };
    case ACTIONS.PACKING_LIST:
      return { ...state, packingList: action.payload };
    case ACTIONS.INVOICE:
      return { ...state, invoice: action.payload };
    case ACTIONS.SHIPPING_COST:
      return { ...state, shippingCost: action.payload };
    case ACTIONS.DELIVERY_INSTRUCTIONS:
      return { ...state, deliveryInstructions: action.payload };
    case ACTIONS.PRODUCT_TYPE:
      return { ...state, productType: action.payload };
    case ACTIONS.RECEPTION_STATE:
      return { ...state, receptionState: action.payload };
    case ACTIONS.APPROVED:
      return { ...state, approved: action.payload };
    default:
      return state;
  }
}

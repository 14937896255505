/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import React from 'react';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  labelClassName?: string;
  divClassName?: string;
  iconClassName?: string;
  withIcon?: string;
  inputType?: string;
  showPassword?: boolean;
  setShowPassword?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function _Input(
    {
      label,
      className = '',
      name,
      labelClassName,
      divClassName = '',
      withIcon,
      iconClassName,
      inputType = '',
      showPassword,
      setShowPassword,
      ...rest
    }: InputProps,
    ref
  ) {
    if (inputType === 'password') {
      return (
        <label
          className="text-sm block w-full font-normal text-neutral-500  gap-4"
          htmlFor={name}
        >
          <span className={`text-primary-500   ${labelClassName}`}>
            {label}
          </span>
          <div
            className={`bg-transparent flex justify-start items-center w-full rounded-lg h-fit gap-2 border-b border-[#606060] ${divClassName} ${
              withIcon ? 'pl-2' : ''
            }`}
          >
            <input
              {...rest}
              className={`text-sm mt-1 block w-full text-font-neutral-400 rounded-md  font-light placeholder-neutral-300 outline-none focus:outline-none focus:shadow-none bg-transparent border-none focus:ring-0  ${className} ${
                withIcon ? '' : 'indent-2'
              }`}
              name={name}
              ref={ref}
            />
            {showPassword ? (
              <EyeIcon
                className="cursor-pointer mr-3 mt-1 text-black"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              />
            ) : (
              <EyeOffIcon
                className="cursor-pointer mr-3 mt-1 text-black"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              />
            )}
          </div>
        </label>
      );
    }
    if (inputType === 'search') {
      return (
        <label
          className="text-sm block w-full font-normal text-neutral-500  gap-4"
          htmlFor={name}
        >
          <span className={`text-primary-500   ${labelClassName}`}>
            {label}
          </span>
          <div
            className={`bg-transparent flex justify-start items-center w-full h-fit gap-2  border border-[#606060] p-2 ${divClassName} ${
              withIcon ? 'pl-2' : ''
            }`}
          >
            <input
              {...rest}
              className={`text-sm block w-full text-font-neutral-400  font-light placeholder-neutral-400 outline-none focus:outline-none focus:shadow-none bg-transparent  ${className} ${
                withIcon ? '' : 'indent-2'
              }`}
              name={name}
              ref={ref}
            />
          </div>
        </label>
      );
    }
    if (inputType === 'text') {
      return (
        <label
          className="text-sm block  w-full font-normal text-neutral-900  gap-4"
          htmlFor={name}
        >
          <span className={`text-primary-500   ${labelClassName}`}>
            {label}
          </span>
          <div
            className={`bg-transparent flex justify-start items-center w-full h-fit gap-2  border border-neutral-200 mt-1 rounded ${divClassName} ${
              withIcon ? 'pl-2' : ''
            }`}
          >
            <input
              {...rest}
              className={`text-sm rounded block w-full text-font-neutral-400  font-light placeholder-neutral-400  border-none outline-none focus:outline-none focus:shadow-none bg-transparent focus:ring-0   ${className} ${
                withIcon ? '' : 'indent-2'
              }`}
              name={name}
              ref={ref}
            />
          </div>
        </label>
      );
    }

    return (
      <label
        className={`text-sm py-1 flex flex-col w-full font-medium text-font-black  gap-2 ${labelClassName} `}
        htmlFor={name}
      >
        {label}
        <div
          className={`flex justify-start items-center w-full  rounded-lg h-fit gap-2 border-[#606060] ${
            divClassName === '' ? '' : 'bg-font-white'
          } ${withIcon ? 'pl-2' : ''}`}
        >
          {/* {withIcon ? getIcon(withIcon, iconClassName) : null} */}
          <input
            {...rest}
            className={`flex w-full rounded-md border bg-white border-neutral-200 shadow-sm bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ${className} ${
              withIcon ? '' : 'indent-2'
            }`}
            name={name}
            ref={ref}
          />
        </div>
      </label>
    );
  }
);

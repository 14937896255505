/* eslint-disable react/button-has-type */
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  withMargin?: boolean;
  color?: string;
  withIcon?: string;
  iconClassName?: string;
  outline?: boolean;
  buttonType?: string;
  active?: boolean;
}

export default function Button({
  children,
  type,
  disabled,
  className,
  withMargin = true,
  color = 'primary',
  outline = false,
  withIcon,
  iconClassName,
  buttonType,
  active,
  ...rest
}: ButtonProps) {
  const classes = `flex gap-2 font-medium justify-center items-center h-fit  py-2 px-3 text-sm text-lemon rounded-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
    !withMargin ? '' : ''
  } `;
  let bgClass;
  let textClass;
  let borderClass;

  switch (color) {
    case 'success':
      bgClass = outline ? 'bg-transparent' : 'bg-success-300';
      textClass = outline ? 'text-success-300' : 'text-font-white';
      borderClass = outline ? 'border-success-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-success-200';
        textClass += ' hover:text-success-200';
        borderClass += ' hover:border-success-200';
      } else {
        bgClass +=
          ' hover:bg-success-200 active:bg-success-400 active:shadow-inner';
      }
      break;
    case 'neutral':
      bgClass = outline ? 'bg-transparent' : 'bg-neutral-400';
      textClass = outline ? 'text-neutral-400' : 'text-font-white';
      borderClass = outline ? 'border-neutral-400' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-neutral-500';
        textClass += ' hover:text-neutral-600';
        borderClass += ' hover:border-neutral-600';
      } else {
        bgClass +=
          ' hover:bg-neutral-500 active:bg-neutral-600 active:shadow-inner';
      }
      break;
    case 'danger':
      bgClass = outline ? 'bg-transparent' : 'bg-danger-300';
      textClass = outline ? 'text-danger-300' : 'text-font-white';
      borderClass = outline ? 'border-danger-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-danger-200';
        textClass += ' hover:text-danger-200';
        borderClass += ' hover:border-danger-200';
      } else {
        bgClass +=
          ' hover:bg-danger-200 active:bg-danger-400 active:shadow-inner';
      }
      break;
    case 'warning':
      bgClass = outline ? 'bg-transparent' : 'bg-warning-300';
      textClass = outline ? 'text-warning-300' : 'text-font-white';
      borderClass = outline ? 'border-warning-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-warning-200';
        textClass += ' hover:text-warning-200';
        borderClass += ' hover:border-warning-200';
      } else {
        bgClass +=
          ' hover:bg-warning-200 active:bg-warning-400 active:shadow-inner';
      }
      break;
    case 'none':
      bgClass = 'bg-transparent';
      textClass = 'text-secondary-500 hover:text-secondary-300';
      borderClass = 'border-transparent';
      break;
    case 'white':
      bgClass = 'bg-transparent';
      textClass = 'text-font-white hover:text-font-white';
      borderClass = 'outline-font-white outline';
      break;
    default:
      bgClass = outline ? 'bg-transparent' : 'bg-primary-600';
      textClass = outline ? 'text-primary-300' : 'text-font-white';
      borderClass = outline ? 'border-primary-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-primary-200';
        textClass += ' hover:text-primary-200';
        borderClass += ' hover:border-primary-200';
      } else {
        bgClass +=
          ' hover:bg-primary-500 active:bg-primary-700 active:shadow-inner';
      }
      break;
  }

  let content = children;
  if (withIcon) {
    content = <div>{children}</div>;
  }

  let button;
  switch (buttonType) {
    case 'small-x':
      button = (
        <button
          type="button"
          className={`flex rounded-md px-2 bg-danger-300 hover:bg-danger-200 active:bg-danger-400 active:shadow-inner text-font-white right-1 top-1 ${className}`}
          {...rest}
        >
          {children}
        </button>
      );
      break;
    case 'home':
      button = (
        <button
          type={type}
          className={`bg-white cursor-pointer hover:scale-105 flex items-center flex-col gap-3 p-3 w-36 shadow-md rounded-md ${className}`}
          disabled={disabled}
          {...rest}
        >
          {children}
        </button>
      );
      break;
    case 'sectionNavbar':
      button = (
        <button
          type={type}
          className={`bg-transparent cursor-pointer border-b font-medium hover:text-primary-400 hover:border-b-secondary-400 flex items-center f flex-col gap-3 px-3 pb-1  text-sm ${className} ${
            active
              ? 'border-b-primary-500 text-primary-500'
              : 'text-neutral-300 border-b-neutral-300'
          }`}
          disabled={disabled}
          {...rest}
        >
          {children}
        </button>
      );
      break;
    case 'blueCategory':
      button = (
        <div className="flex flex-row bg-primary-100 border border-secondary-500 gap-3 px-2  justify-center items-center rounded-md h-fit">
          <p className="text-secondary-500 text-xs font-normal">{children}</p>
          <button
            type={type}
            className={`text-xs text-secondary-500 font-normal ${className}`}
            disabled={disabled}
            {...rest}
          />
        </div>
      );
      break;
    case 'tertiary':
      button = (
        <button
          type={type}
          className={` text-font-white  border-b-neutral-400 border-b-2  hover:text-neutral-200 hover:border-b-font-white cursor-pointer active:text-font-white active:border-b-font-white active:shadow-inner px-2 h-full w-full text-xsm ${className}`}
          disabled={disabled}
          {...rest}
        >
          {content}
        </button>
      );
      break;
    case 'hero-button':
      button = (
        <button
          type={type}
          className={` text-font-white font-bold  hover:border-b-primary-200 cursor-pointer active:text-font-white active:border-b-primary-200 active:shadow-inner px-2 h-full w-full text-xl ${className}`}
          disabled={disabled}
          {...rest}
        >
          <span className="text-xl">{content}</span>
        </button>
      );
      break;
    default:
      button = (
        <button
          type={type}
          className={`${classes} ${bgClass} ${borderClass} ${textClass}`}
          disabled={disabled}
          {...rest}
        >
          {content}
        </button>
      );
      break;
  }

  return button;
}

import React from 'react';
import { ACTIONS, ShipmentActions } from './shippingReducer';
import { Input } from '../../../../common/input/Input';
import Button from '../../../../common/button/Button';
import { Shipment } from '../../../../../models';

interface PersonalInformationProps {
  state: Partial<Shipment>;
  dispatch: React.Dispatch<ShipmentActions>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

function PersonalInformation({
  state,
  dispatch,
  setSelected,
}: PersonalInformationProps) {
  const { firstName, lastName, dni, email, phone } = state;
  return (
    <div className="w-full  flex flex-wrap">
      <Input
        label="Nombre"
        labelClassName="w-full px-2 md:w-1/2"
        onChange={(e) => {
          dispatch({ type: ACTIONS.FIRST_NAME, payload: e.target.value });
        }}
        value={firstName}
      />
      <Input
        label="Apellido"
        labelClassName="w-full px-2 md:w-1/2"
        onChange={(e) => {
          dispatch({ type: ACTIONS.LAST_NAME, payload: e.target.value });
        }}
        value={lastName}
      />
      <Input
        label="Cédula de identidad"
        labelClassName="w-full px-2 md:w-1/2"
        onChange={(e) => {
          dispatch({ type: ACTIONS.DNI, payload: e.target.value });
        }}
        value={dni}
      />
      <Input
        label="Email"
        labelClassName="w-full px-2 md:w-1/2"
        onChange={(e) => {
          dispatch({ type: ACTIONS.EMAIL, payload: e.target.value });
        }}
        value={email}
      />
      <Input
        label="Número de contacto"
        labelClassName="w-full px-2 md:w-1/2"
        onChange={(e) => {
          dispatch({ type: ACTIONS.PHONE, payload: e.target.value });
        }}
        value={phone}
      />
      <Input
        label="Número de contacto"
        labelClassName="w-full px-2 md:w-1/2"
        onChange={(e) => {
          dispatch({ type: ACTIONS.PHONE, payload: e.target.value });
        }}
        value={phone}
      />
      <hr className="w-full border-neutral-300 mt-2 mx-2" />
      <Button
        className="mx-2 w-full mt-2 "
        onClick={(e) => {
          e.preventDefault();
          setSelected('address');
        }}
      >
        Siguiente
      </Button>
    </div>
  );
}

export default PersonalInformation;

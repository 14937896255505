// calculatorInfo.ts

interface ShippingRate {
  minCbm: number;
  maxCbm: number;
  rate: number;
}

interface ShippingRateInfo {
  receptionPlace: string;
  shippingType: string;
  rates: ShippingRate[];
}

export const receptionPlaces = [
  {
    name: 'Distrito Capital',
    value: 'caracas',
    receptioRegion: 'caracas',
  },
  {
    name: 'Amazonas',
    value: 'amazonas',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Anzoátegui',
    value: 'anzoategui',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Apure',
    value: 'apure',
    receptioRegion: 'regionCentralYLosLlanos',
  },
  {
    name: 'Aragua',
    value: 'aragua',
    receptioRegion: 'regionCentralYLosLlanos',
  },
  {
    name: 'Barinas',
    value: 'barinas',
    receptioRegion: 'regionCentralYLosLlanos',
  },
  {
    name: 'Bolívar',
    value: 'bolivar',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Carabobo',
    value: 'carabobo',
    receptioRegion: 'regionCentralYLosLlanos',
  },
  {
    name: 'Cojedes',
    value: 'cojedes',
    receptioRegion: 'regionCentralYLosLlanos',
  },
  {
    name: 'Delta Amacuro',
    value: 'delta-amacuro',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Falcón',
    value: 'falcon',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Guárico',
    value: 'guarico',
    receptioRegion: 'regionCentralYLosLlanos',
  },
  {
    name: 'Lara',
    value: 'lara',
    receptioRegion: 'regionOccidental',
  },
  {
    name: 'Mérida',
    value: 'merida',
    receptioRegion: 'regionOccidental',
  },
  {
    name: 'Miranda',
    value: 'miranda',
    receptioRegion: 'regionCentralYLosLlanos',
  },
  {
    name: 'Monagas',
    value: 'monagas',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Nueva Esparta',
    value: 'nueva-esparta',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Portuguesa',
    value: 'portuguesa',
    receptioRegion: 'regionOccidental',
  },
  {
    name: 'Sucre',
    value: 'sucre',
    receptioRegion: 'regionOrientalNorteYSur',
  },
  {
    name: 'Táchira',
    value: 'tachira',
    receptioRegion: 'regionOccidental',
  },
  {
    name: 'Trujillo',
    value: 'trujillo',
    receptioRegion: 'regionOccidental',
  },
  {
    name: 'Yaracuy',
    value: 'yaracuy',
    receptioRegion: 'regionOccidental',
  },
  {
    name: 'Zulia',
    value: 'zulia',
    receptioRegion: 'regionOccidental',
  },
  {
    name: 'La Guaira',
    value: 'la-guaira',
    receptioRegion: 'regionCentralYLosLlanos',
  },
];

export const shippingTypes = [
  {
    name: 'Aéreo',
    value: 'air',
  },
  {
    name: 'Marítimo',
    value: 'sea',
  },
];

export const ratesBySeaAndRegion = [
  {
    key: 'caracas',
    rates: [
      {
        minCbm: 0.001,
        maxCbm: 0.39,
        rate: 650,
      },
      {
        minCbm: 0.4,
        maxCbm: 0.59,
        rate: 598.1,
      },
      {
        minCbm: 0.6,
        maxCbm: 1.99,
        rate: 585.55,
      },
      {
        minCbm: 2,
        maxCbm: 4.99,
        rate: 552.82,
      },
      {
        minCbm: 5,
        maxCbm: 9.99,
        rate: 522.82,
      },
      {
        minCbm: 10,
        maxCbm: Infinity,
        rate: 480.99,
      },
    ],
  },
  {
    key: 'regionCentralYLosLlanos',
    rates: [
      {
        minCbm: 0.001,
        maxCbm: 0.39,
        rate: 0,
      },
      {
        minCbm: 0.4,
        maxCbm: 0.59,
        rate: 704.21,
      },
      {
        minCbm: 0.6,
        maxCbm: 1.99,
        rate: 679.06,
      },
      {
        minCbm: 2,
        maxCbm: 4.99,
        rate: 653.91,
      },
      {
        minCbm: 5,
        maxCbm: 9.99,
        rate: 628.76,
      },
      {
        minCbm: 10,
        maxCbm: Infinity,
        rate: 578.46,
      },
    ],
  },
  {
    key: 'regionOrientalNorteYSur',
    rates: [
      {
        minCbm: 0.001,
        maxCbm: 0.39,
        rate: 0,
      },
      {
        minCbm: 0.4,
        maxCbm: 0.59,
        rate: 743.76,
      },
      {
        minCbm: 0.6,
        maxCbm: 1.99,
        rate: 717.2,
      },
      {
        minCbm: 2,
        maxCbm: 4.99,
        rate: 690.64,
      },
      {
        minCbm: 5,
        maxCbm: 9.99,
        rate: 664.08,
      },
      {
        minCbm: 10,
        maxCbm: Infinity,
        rate: 610.95,
      },
    ],
  },
  {
    key: 'regionOccidental',
    rates: [
      {
        minCbm: 0.001,
        maxCbm: 0.39,
        rate: 0,
      },
      {
        minCbm: 0.4,
        maxCbm: 0.59,
        rate: 862.45,
      },
      {
        minCbm: 0.6,
        maxCbm: 1.99,
        rate: 831.62,
      },
      {
        minCbm: 2,
        maxCbm: 4.99,
        rate: 800.82,
      },
      {
        minCbm: 5,
        maxCbm: 9.99,
        rate: 770.02,
      },
      {
        minCbm: 10,
        maxCbm: Infinity,
        rate: 708.42,
      },
    ],
  },
];

export function getShippingRates(
  ratesByRegion: any[],
  receptionRegion: string,
  shippingType: string
): ShippingRate[] {
  const shippingRateInfo = ratesByRegion.find(
    (info) => info.key === receptionRegion
  );

  if (!shippingRateInfo) {
    throw new Error(
      `No shipping rates found for the reception place '${receptionRegion}' and shipping type '${shippingType}'.`
    );
  }

  return shippingRateInfo.rates;
}

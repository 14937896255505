import React from 'react';
import { Shipment } from '../../../../../models';
import { TextArea } from '../../../../common/input/TextArea';

import { ACTIONS, ShipmentActions } from './shippingReducer';
import { Input } from '../../../../common/input/Input';
import { Select } from '../../../../common/select/Select';
import { receptionPlaces } from '../../../../../utils/calculatorInfo';
import { Button } from '../../../../ui/button';
import { useUser } from '../../../../../hooks';

interface AddressProps {
  state: Partial<Shipment>;
  dispatch: React.Dispatch<ShipmentActions>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

function Address({ state, dispatch, setSelected }: AddressProps) {
  const { address, addressUrl, postalCode, receptionState } = state;
  const [open, setOpen] = React.useState(false);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full flex gap-4 flex-col md:flex-col justify-center items-start">
        <div className="flex w-full md:flex-row flex-col items-center justify-start">
          <Input
            label="Código postal"
            labelClassName="w-full px-2 mb-1 "
            onChange={(e) => {
              dispatch({
                type: ACTIONS.POSTAL_CODE,
                payload: e.target.value,
              });
            }}
            value={postalCode}
          />
          {/* Select element for reception place */}
          <Select
            label="Lugar de recepción"
            labelClassName=" w-full px-2  text-sm font-medium "
            onChange={(e) => {
              dispatch({
                type: ACTIONS.RECEPTION_STATE,
                payload: e.target.value,
              });
            }}
            value={receptionState}
          >
            {receptionPlaces?.map((place) => (
              <option key={place.value} value={place.value}>
                {place.name}
              </option>
            ))}
          </Select>
        </div>
        <TextArea
          label="Dirección de entrega en Venezuela"
          value={address}
          onChange={(e) => {
            dispatch({ type: ACTIONS.ADDRESS, payload: e.target.value });
          }}
          className=" w-full "
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelected('shipmentInformation');
          }}
          className="ml-4"
        >
          Siguiente
        </Button>

        {/* <Button
            className="w-1/3 text-xsm"
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            Agregar ubicación
          </Button> */}
      </div>
    </div>
  );
}

export default Address;
